$base-font:		'Raleway', sans-serif;
// $font-alt:      'Roboto', sans-serif;

$base:          #565656;
$white:			#fff;
$black:			#000;
$gold:			#bea67c;
$alt:			#777777;
$gray:			#464646;
$lgray:         #ececec;
$dgray:         #232323;
$borderColor:  #fafafa;
$bgc:           #1E1E1E;

$light:			300;
$regular:		400;
$medium:        500;
$semi-bold:		600;
$bold:			700;

$border: 2px solid $borderColor;