@import "../globals/variables";
.login{
	padding: 75px 0 0;
	background: $base;
	#login{
		background: $white;
		padding: 20px 0;
		h1 a {
			background-image: url('../../images/logo-studio.png');
			width: 100%;
			background-size: contain;
		}
	}
	.button.button-large {
		height: 40px;
	}
	label {
		font-size: 12px;
		color: #555555;
	}
	input{
		background-color: #ffffff;
		border-color:#dddddd;
		border-radius: 0px;
		outline:0;
		box-shadow: 0;
	}

	.button-primary {
		display: block;
		width: 100%;
		margin-top: 30px;
		text-transform: uppercase;
		font-weight: bold;
		letter-spacing: 3px;
		text-shadow: none;
		box-shadow: none;
		background: $white;
		border:none;
		border: 1px solid $base;
		color: $base;
		transition: .3s ease;
		&:hover{
			background: $alt;
			border: 1px solid $alt;
		}
	}
}